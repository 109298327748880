<template>
  <Doughnut
    ref="doughnut"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    :click="handleChartClick"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 340,
    },
    height: {
      type: Number,
      default: 340,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    totals: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            // backgroundColor: ["#53A9D8", "#B2D2D6"],
            backgroundColor: ["#411E6D", "#53A9D8"],
            data: [this.totals.emails, this.totals.sms],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        events: ["click"],
        onClick: (e) => {
          const CHART = e.chart;
          const points = CHART.getElementsAtEventForMode(
            e,
            "nearest",
            {
              intersect: true,
            },
            true
          );
          // MÉTODO PARA ENVIAR QUAL LABEL E O SEU VALOR QUE FOI CLICADO
          if (points[0]) {
            // const dataset = points[0].datasetIndex;
            const index = points[0].index;
            const CURRENT_CLICKED_LABEL = CHART.data.labels[index];
            // const CURRENT_CLICKED_VALUE = CHART.data.datasets[dataset].data[index];
            const low = `${CURRENT_CLICKED_LABEL}`.toLocaleLowerCase();
            this.$emit("click", low);
          }
        },
        plugins: {
          tooltip: {
            // events: ["click"],
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }

                label +=
                  parseFloat(context.parsed).toLocaleString("pt-BR") +
                  " (" +
                  (
                    (+context.parsed /
                      context.dataset.data.reduce((a, b) => +a + +b, 0)) *
                    100
                  ).toFixed(2) +
                  "%)";

                return label;
              },
            },
          },
        },
      },
    };
  },
  methods: {
    handleChartClick(event, chartElements) {
      console.log(event, "=====>", chartElements);
    },
  },
};
</script>
