<template>
  <div style="padding-top: 1rem">
    <div class="reports_filter px-5 mb-8">
      <div class="px-4 py-4">
        <z-filter
          :interval-filters-list-prop="intervalFilters"
          :tempaltes-list-prop="templates"
          :journeys-list-prop="journeyOptions"
          :filter-options-prop="itemsFilterList"
          @chip-created="onUpdateChipList"
          @remove-all-chips="onRemoveAllChips"
          @remove-single-chip="onUpdateChipList"
          @update-chip="onUpdateChipList"
          @remove-child-chip="onUpdateChipList"
        />
      </div>
    </div>

    <div v-if="!isLoading" class="wrapper__card mx-5">
      <div class="wrapper__card__body">
        <v-row class="px-3 mb-3 d-flex align-center justify-space-between">
          <div class="wrapper__card__body__header">
            <div
              v-for="item in metricsTypes"
              :key="item.id + item.type"
              class="metric-type mb-2"
            >
              <div class="dot" :style="{ background: item.color }"></div>
              <small :style="{ color: item.color }">{{ item.type }}</small>
            </div>
          </div>

          <div>
            <z-btn
              rounded
              primary
              text="Extração de dados"
              icon="$upload_file"
              small
              class="mr-2"
              @click="$router.push(`/dataExtraction/company`)"
            />

            <z-btn
              rounded
              primary
              text="Consumo de Creditos"
              icon="$mdi-currency-usd"
              small
              class="mr-2"
              @click="$router.push(`/usage`)"
            />
          </div>
        </v-row>

        <div class="wrapper__card__body__cards">
          <!-- MÉTRICAS GERAIS -->

          <v-row style="width: 100%">
            <v-col cols="12">
              <h2>Metricas Gerais</h2>
            </v-col>

            <v-col cols="3">
              <CardReport
                icon="$send"
                main-color-prop="rgb(246, 189, 2)"
                border-color-prop="#ffce4d"
              >
                <template #title> TOTAL ENVIOS </template>
                <template #value>{{
                  formateNumberToLocal(totalSent) || 0
                }}</template>
              </CardReport>
            </v-col>
          </v-row>

          <v-row style="width: 100%">
            <v-col cols="12">
              <h2>Metricas SMS</h2>
            </v-col>

            <!-- METRICAS DE SMS -->
            <v-col cols="3">
              <CardReport
                icon="$send"
                main-color-prop="rgb(246, 189, 2)"
                border-color-prop="#30895e"
              >
                <template #title> TOTAL ENVIOS SMS </template>
                <template #value>{{
                  formateNumberToLocal(totalSMSSent) || 0
                }}</template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="$arrow_back"
                main-color-prop="rgba(73, 203, 140, 1)"
                border-color-prop="#30895e"
              >
                <template #title> TOTAL CLIQUES SMS </template>
                <template #value>
                  {{ formateNumberToLocal(totalSMSClicks) || 0 }}

                  {{ formatePercentToChips(totalSMSClicks, totalSMSSent) }}
                </template>
              </CardReport>
            </v-col>
          </v-row>

          <!-- METRICAS DE EMAIL -->

          <v-row style="width: 100%">
            <v-col cols="12">
              <h2>Metricas Email</h2>
            </v-col>

            <v-col cols="3">
              <CardReport
                icon="$send"
                main-color-prop="rgb(246, 189, 2)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL ENVIOS EMAIL</template>
                <template #value>{{
                  formateNumberToLocal(totalEmailSent) || 0
                }}</template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-open-outline"
                main-color-prop="rgb(105, 188, 203)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL ENTREGUE EMAIL</template>
                <template #value>
                  {{ formateNumberToLocal(totalEmailDelivery) || 0 }}

                  {{ formatePercentToChips(totalEmailDelivery, totalEmailSent) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-open-outline"
                main-color-prop="rgb(105, 188, 203)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL ABERTURAS EMAIL</template>
                <template #value>
                  {{ formateNumberToLocal(totalEmailOpens) || 0 }}

                  {{ formatePercentToChips(totalEmailOpens, totalEmailSent) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="$arrow_back"
                main-color-prop="rgba(73, 203, 140, 1)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL CLIQUES EMAIL</template>
                <template #value>
                  {{ formateNumberToLocal(totalEmailClicks) }}

                  {{ formatePercentToChips(totalEmailClicks, totalEmailSent) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-sync-outline"
                main-color-prop="rgb(100, 45, 108)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL BOUNCES EMAIL</template>
                <template #value>
                  {{ formateNumberToLocal(totalEmailBounces) }}

                  {{ formatePercentToChips(totalEmailBounces, totalEmailSent) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-alert-octagon-outline"
                main-color-prop="rgb(222, 87, 66)"
                border-color-prop="#411e6d"
              >
                <template #title> TOTAL SPAM EMAIL </template>
                <template #value>
                  {{ formateNumberToLocal(totalEmailSpammed) }}

                  {{ formatePercentToChips(totalEmailSpammed, totalEmailSent) }}
                </template>
              </CardReport>
            </v-col>
          </v-row>

          <v-row style="width: 100%">
            <v-col cols="12">
              <h2>Metricas Push</h2>
            </v-col>

            <v-col cols="3">
              <CardReport
                icon="mdi-alert-octagon-outline"
                main-color-prop="rgb(246, 189, 2)"
                border-color-prop="#53A9D8"
              >
                <template #title> TOTAL DE ENVIOS </template>
                <template #value>
                  {{ formateNumberToLocal(pushInfosCards?.count_total) }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-open-outline"
                main-color-prop="rgb(105, 188, 203)"
                border-color-prop="#53A9D8"
              >
                <template #title> ABERTURAS PUSH </template>
                <template #value>
                  {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                  {{
                    formatePercentToChips(
                      pushInfosCards?.count_opened,
                      pushInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="$arrow_back"
                main-color-prop="rgba(73, 203, 140, 1)"
                border-color-prop="#53A9D8"
              >
                <template #title> CLIQUES PUSH </template>
                <template #value>
                  {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                  {{
                    formatePercentToChips(
                      pushInfosCards?.count_clicked,
                      pushInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-email-sync-outline"
                main-color-prop="rgb(100, 45, 108)"
                border-color-prop="#53A9D8"
              >
                <template #title> BOUNCES PUSH </template>
                <template #value>
                  {{ formateNumberToLocal(pushInfosCards?.count_opened) }}
                  {{
                    formatePercentToChips(
                      pushInfosCards?.count_spam,
                      pushInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
            <v-col cols="3">
              <CardReport
                icon="mdi-alert-octagon-outline"
                main-color-prop="rgb(222, 87, 66)"
                border-color-prop="#53A9D8"
              >
                <template #title> SPAM PUSH </template>
                <template #value>
                  {{ formateNumberToLocal(pushInfosCards?.count_opened) }}

                  {{
                    formatePercentToChips(
                      pushInfosCards?.count_spam,
                      pushInfosCards?.count_total
                    )
                  }}
                </template>
              </CardReport>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col lg="8" md="8" sm="12" xs="12" cols="12" class="mt-2">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <z-tabs android :tabs="tabs" @change="actualizeTab">
                <template #send>
                  <section>
                    <aside class="d-flex justify-end">
                      <v-icon
                        v-show="sendChartDataInformationArr.length > 1"
                        @click="drillUpGraph('send')"
                        >$arrow_upward_rounded</v-icon
                      >
                    </aside>

                    <BarChart
                      v-if="!sendChartDataInformationLoading"
                      :chart-data="sendChartDataInformation"
                      :chart-options="sendChartOptions"
                    />
                    <aside
                      v-else
                      class="d-flex align-center justify-center"
                      style="height: 320px"
                    >
                      <z-loading />
                    </aside>
                  </section>
                </template>
                <template #engage>
                  <section>
                    <aside class="d-flex justify-end">
                      <v-icon
                        v-show="engageChartDataInformationArr.length > 1"
                        @click="drillUpGraph('engage')"
                        >$arrow_upward_rounded</v-icon
                      >
                    </aside>
                    <BarChart
                      v-if="!engageChartDataInformationLoading"
                      :chart-options="engageChartOptions"
                      :chart-data="engageChartDataInformation"
                    />

                    <aside
                      v-else
                      class="d-flex align-center justify-center"
                      style="height: 320px"
                    >
                      <z-loading />
                    </aside>
                  </section>
                </template>
              </z-tabs>
            </v-card>
          </v-col>
          <!-- <v-col cols="4">
            <v-card elevation="2" shaped class="mx-3 py-2 px-5">
              <v-row class="ml-2 mt-2">
                <span style="font-size: 0.8rem; font-weight: 600">TAXA DE CLIQUES</span>
              </v-row>
              <LineChart
                v-if="clicksData"
                :label="''"
                :data="clicksData"
                :lineColor="'rgba(255, 143, 40, 1)'"
              />
            </v-card>
          </v-col> -->
          <v-col lg="4" md="4" sm="12" xs="12" cols="12" class="mt-2">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <v-row
                class="ml-2 mt-2 d-flex align-center justify-space-between"
              >
                <span style="font-size: 0.8rem; font-weight: 600">CANAL</span>
                <v-icon
                  v-if="filter.channel"
                  color="#004C91"
                  @click="doughnutChartCancel"
                  >$settings_backup_restore</v-icon
                >
              </v-row>
              <DoughnutChart
                v-if="totalByChannel"
                :labels="['Email', 'SMS']"
                :totals="totalByChannel"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card elevation="2" height="420" shaped class="mx-3 py-2 px-5">
              <BarChart
                v-if="consumeChardDataInformation"
                :chart-data="consumeChardDataInformation"
                :chart-options="sendChartOptions"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <div class="px-5 py-4 d-flex justify-space-between align-center">
                <strong>TABELA DE JORNADAS</strong>
                <z-btn
                  small
                  rounded
                  primary
                  text=""
                  :icon="`mdi-cloud-download`"
                  :is-loading="loadingDownloadJourneys"
                  @click="downloadDocument"
                />
              </div>
              <z-pagination
                v-if="!loadingJourneys"
                :auto-pagination="false"
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="10"
                :items="journeys || []"
                :current-page="journeyTableFilter.page"
                :number-of-pages="journeyTableFilter.totalPages"
                @click:next="goNextPageJourneysReportsByCompanyId"
                @click:previous="backPreviousPageJourneysReportsByCompanyId"
              >
                <template #data="{ paginatedItems }">
                  <div
                    class="table-wrapper"
                    style="
                      height: 550px !important;
                      max-height: 550px !important;
                    "
                  >
                    <z-table
                      class="table"
                      :headers="headers"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      :is-loading="loadingSortTable"
                      @sort="getSortedJourneysReportsByCompanyId($event)"
                    >
                      <template #no-data>
                        <div
                          class="no-data-table d-flex align-end justify-center"
                        >
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>

                      <template v-slot:[`item.dateCreated`]="{ item }">
                        <span>{{ formatDate(item.dateCreated) }} </span>
                      </template>

                      <template v-slot:[`item.status`]="{ item }">
                        <span
                          >{{
                            item.status === "active" ? "Ativa" : "Finalizada"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.journeyType`]="{ item }">
                        <span>{{ item.journeyType }} </span>
                      </template>
                      <template v-slot:[`item.nextUpdate`]="{ item }">
                        <span
                          >{{
                            item.status === "active"
                              ? formatDate(item.nextUpdate) || "-"
                              : "-"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.clicked`]="{ item }">
                        <span>{{ item.clicked || "0" }} </span>
                      </template>

                      <template v-slot:[`item.opened`]="{ item }">
                        <span>{{ item.opened || "0" }} </span>
                      </template>

                      <template v-slot:[`item.sent`]="{ item }">
                        <span>{{ item.sent || "0" }} </span>
                      </template>

                      <template v-slot:[`item.lastSend`]="{ item }">
                        <span
                          >{{ formatDate(item.lastSend) || "Sem informação" }}
                        </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon @click="goToRoute('journeyDetailPage', item.id)"
                          >$insert_chart_outlined</v-icon
                        >
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
              <div
                v-else
                class="wrapper no-data"
                style="height: 550px !important; max-height: 550px !important"
              >
                <div class="wrapper__card" style="height: 100% !important">
                  <z-loading />
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" class="mt-5">
            <v-card elevation="2" shaped class="mx-3 py-2">
              <div class="px-5 py-4 d-flex justify-space-between align-center">
                <strong>TABELA DE TEMPLATES</strong>
                <z-btn
                  small
                  rounded
                  primary
                  text=""
                  :icon="`mdi-cloud-download`"
                  :is-loading="loadingDownloadTemplates"
                  @click="downloadDocumentTemplates"
                />
              </div>
              <z-pagination
                v-if="!loadingTemplates"
                :auto-pagination="false"
                icon-previous="mdi-chevron-left"
                icon-next="mdi-chevron-right"
                :items-per-page="10"
                :number-of-items="templates.length"
                :items="templates"
                :current-page="templateTableFilter.page"
                :number-of-pages="templateTableFilter.totalPages"
                @click:next="goNextPageTemplatesReportsByCompanyId"
                @click:previous="backPreviousPageTemplatesReportsByCompanyId"
              >
                <template #data="{ paginatedItems }">
                  <div
                    class="table-wrapper"
                    style="
                      height: 550px !important;
                      max-height: 550px !important;
                    "
                  >
                    <z-table
                      class="table"
                      :headers="headersTemplates"
                      :items="paginatedItems"
                      icon-next="mdi-chevron-right"
                      icon-previous="mdi-chevron-left"
                      :is-loading="loadingSortTableTemplate"
                      @sort="getSortedTemplateReportsByCompanyId($event)"
                    >
                      <template #no-data>
                        <div
                          class="no-data-table d-flex align-end justify-center"
                        >
                          <h3>Sem dados disponíveis.</h3>
                        </div>
                      </template>

                      <template v-slot:[`item.openRate`]="{ item }">
                        <span>{{
                          item.type === "email"
                            ? item.openRate.toFixed(2) + "%" || "0%"
                            : "-"
                        }}</span>
                      </template>

                      <template v-slot:[`item.totalSent`]="{ item }">
                        <span>{{ item.totalSent || "0" }}</span>
                      </template>

                      <template v-slot:[`item.clickRate`]="{ item }">
                        <span>{{ item.clickRate.toFixed(2) || "0" }}%</span>
                      </template>

                      <template v-slot:[`item.bounceRate`]="{ item }">
                        <span>{{
                          item.type === "email"
                            ? item.bounceRate.toFixed(2) + "%" || "0%"
                            : "-"
                        }}</span>
                      </template>

                      <template v-slot:[`item.spamRate`]="{ item }">
                        <span
                          >{{
                            item.type === "email"
                              ? item.spamRate.toFixed(2) + "%" || "0"
                              : "-"
                          }}
                        </span>
                      </template>

                      <template v-slot:[`item.optOutRate`]="{ item }">
                        <span
                          >{{
                            item.type === "email"
                              ? item.optOutRate.toFixed(2) + "%" || "0"
                              : "-"
                          }}
                        </span>
                      </template>
                    </z-table>
                  </div>
                </template>
              </z-pagination>
              <div
                v-else
                class="wrapper no-data"
                style="height: 550px !important; max-height: 550px !important"
              >
                <div class="wrapper__card" style="height: 100% !important">
                  <z-loading />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="dontHaveAllDatasToCards" class="wrapper__card__overlay">
        <div class="message">
          <span>
            Não foram encontrados resultados com esses filtros. Tente refazer
            sua pesquisa.
          </span>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="wrapper__card">
        <v-row justify="center">
          <z-loading />
        </v-row>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable no-undef -->
<script>
import { computed } from "vue";
import ZFilter from "@/components/Shared/filter/filterContainer.vue";
import ApiClient from "@/services/ApiClient";
import moment from "moment";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import BarChart from "./components/Bar.vue";
import DoughnutChart from "./components/Doughnut.vue";
import CardReport from "../../Shared/ZCardReportInfo.vue";
export default {
  components: {
    ZFilter,
    // LineChart,
    BarChart,
    DoughnutChart,
    CardReport,
    // ZDatePicker,
    // DatePicker,
  },
  data() {
    return {
      filters: [],
      pushInfosCards: null,
      metricsTypes: [
        {
          type: "Métrics Gerais",
          id: 0,
          color: "#FFCE4D",
        },
        {
          type: "Métricas de SMS",
          id: 2,
          color: "#30895E",
        },
        {
          type: "Métricas de E-mail",
          id: 2,
          color: "#411E6D",
        },
        {
          type: "Métricas de Push",
          id: 3,
          color: "#53A9D8",
        },
      ],
      pickerRange: "",
      isFilteringGraph: false,
      isFilteringHour: false,
      loadingSortTable: false,
      loadingSortTableTemplate: false,
      orderCampaingTemplateMessage: "",
      orderJourneyMessage: "",
      orderTemplateMessage: "",
      selectedJourneyInModal: "",
      selectedTemplateInJourney: "",
      tableName: "",
      tableTemplateName: "",
      orderTempalteName: "",
      orderName: "",
      closeOnContentClick: false,
      sortDesc: false,
      currentTemplateTableUrl: null,
      currentJourneyTableUrl: null,
      sendChartDataInformationArr: [],
      engageChartDataInformationArr: [],
      sendChartDataInformation: {},
      sendChartDataInformationLoading: false,
      engageChartDataInformationLoading: false,
      engageChartDataInformation: {},
      sendChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        //  onClick: (e) => this.getChartClickedInfo(e.chart, e, "send"),
        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
          },
        },
      },

      engageChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // onClick: (e) => this.getChartClickedInfo(e.chart, e, "engage"),

        scales: {
          x: {
            stacked: false,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: false,
            ticks: {
              callback: function (value) {
                return value;
              },
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let A = `${context.parsed.y}`;
                let B = A.replace(".", ",");
                let label = `${context.dataset.label} ${B}%` || "";
                return label;
              },
            },
          },
        },
      },

      actualTab: "",

      tabs: [
        { id: "send", text: "Quantidade de envios" },
        { id: "engage", text: "Engajamento" },
      ],

      selectedInterval: 1,
      loadingDownloadJourneys: false,
      loadingDownloadTemplates: false,
      http: new ApiClient(),
      isLoading: true,
      totalSent: 0,
      totalEmailSent: 0,
      totalSMSSent: 0,
      totalSpamed: 0,
      totalEmailSpammed: 0,
      totalOptOuts: 0,
      totalEmailBounces: 0,
      totalEmailOpens: 0,
      totalEmailInjected: 0,
      totalEmailDelivery: 0,
      totalClicks: 0,
      totalEmailClicks: 0,
      companyId: "",
      totalSMSClicks: null,
      totalByChannel: null,
      openRateByDateRange: null,
      clickRateByDateRange: null,
      clicksData: null,
      journeys: null,
      opensData: null,
      journeyFilterSelected: null,
      itemsFilterList: [
        {
          text: "Jornada",
          value: "journey",
          child_component:
            "@/components/Shared/filter/components/journeyList.vue",
          component_name: "journeyList.vue",
        },
        {
          text: "Template",
          value: "templates",
          child_component: "./components/templateList.vue",
          component_name: "templateList.vue",
        },
        {
          text: "Intervalo de tempo",
          value: "interval",
          child_component: "./components/intervalList.vue",
          component_name: "intervalList.vue",
        },
        // {
        //   text: "Ordenação",
        //   value: "order",
        //   child_component: "./components/journeyOrderList.vue",
        //   component_name: "journeyOrderList.vue",
        // },
      ],
      temporalFilters: [
        { name: "Pontual", value: "punctual", id: 0 },
        { name: "Recorrente", value: "recurring", id: 1 },
      ],
      intervalFilters: [
        {
          name: "Personalizado",
          text: "Personalizado",
          value: 6,
        },
        {
          name: "Últimos 12 meses",
          text: "Últimos 12 meses",
          value: 1,
          textDate: this.getLastTwelveMonthsUltilDate(),
        },
        {
          name: "Ano até à data",
          text: "Ano até à data",
          value: 2,
          textDate: this.getYearUntilDate(),
        },
        {
          name: "Último ano",
          text: "Último ano",
          value: 3,
          textDate: this.getAllLastYear(),
        },
        {
          name: "Mês até à data",
          text: "Mês até à data",
          value: 4,
          textDate: this.getMonthUntilToday(),
        },
        {
          name: "Último mês",
          text: "Último mês",
          value: 5,
          textDate: this.getLastMonth(),
        },
        {
          name: "Últimos 30 dias",
          text: "Últimos 30 dias",
          textDate: this.getLasThirtyDays(),
          value: 7,
        },
      ],
      headersTemplates: [
        { text: "Template", value: "name", sortable: true },
        { text: "Tipo", value: "type", sortable: true },
        { text: "Total de Envios", value: "totalSent", sortable: false },
        { text: "Abertos (%)", value: "openRate", sortable: false },
        { text: "Bounces (%)", value: "bounceRate", sortable: false },
        { text: "Cliques (%)", value: "clickRate", sortable: false },
        { text: "OptOut (%)", value: "optOutRate", sortable: false },
        { text: "Spam (%)", value: "spamRate", sortable: false },
      ],
      headers: [
        { text: "Campanha", value: "name", sortable: true },
        { text: "Data de criação", value: "dateCreated", sortable: true },
        { text: "Último envio", value: "lastSend", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Tipo", value: "journeyType", sortable: false },
        { text: "Próxima atualização", value: "nextUpdate", sortable: false },
        { text: "Envios", value: "sent", sortable: false },
        { text: "Abertos", value: "opened", sortable: false },
        { text: "Cliques", value: "clicked", sortable: false },
        { text: "Ações", value: "actions" },
      ],
      headersFilter: [
        { text: "Campanha", value: "name", sortable: true },
        { text: "Data de criação", value: "dateCreated", sortable: true },
        { text: "Data último envio", value: "lastSend", sortable: true },
      ],
      headersTemplateFilter: [
        { text: "Template", value: "name", sortable: true },
        { text: "Tipo", value: "type", sortable: true },
      ],
      order: [
        { text: "Crescente", value: "ASC" },
        { text: "Decrescente", value: "DESC" },
      ],
      templates: [],
      paginatedItems: [],
      paginatedTemplatesItems: [],
      filtered: [],
      journeyOptions: [],
      templateOptions: [],
      recent: [],
      filter: {
        selectedJourney: null,
        selectedTemplate: null,
        endsAt: null,
        groupBy: "month",
      },
      journeyTableFilter: {
        page: 1,
        totalPages: 1,
      },
      templateTableFilter: {
        page: 1,
        totalPages: 1,
      },
      loadingJourneys: true,
      loadingTemplates: true,
      consumeChardDataInformation: null,
      consumeChardDataInformationLoading: false,
    };
  },
  provide() {
    // necessário fazer a injeção de dependencia dos dados dos componentes para o chip
    return {
      journeyList: computed(() => this.journeyOptions),
      templateList: computed(() => this.templates),
      intervalList: computed(() => this.intervalFilters),

      // para os componentes de ordenação  de jornadas e templates
      journeyFields: computed(() => this.headersFilter),
      templatesFields: computed(() => this.headersTemplateFilter),
      orders: computed(() => this.order),
    };
  },
  async created() {
    this.isLoading = true;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(async () => {
      let user = JSON.parse(localStorage.getItem("zooxeye-user"));

      if (user) this.companyId = user.companyId;

      const INFO = this.intervalFilters.find((e) => e.value == 7);
      this.$store.dispatch("app/CREATE_DEFAULT_CHIP", INFO);
      this.filters = this.$store.getters["app/$chipsFilterList"];

      let dateInformation = this.filters.find(
        (chip) => chip.chip_type === "interval"
      );

      this.filter.endsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_end_date
      );
      this.filter.startsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_start_date
      );

      await this.chooseGroupBy();
      this.getAllJourneys();
      this.getAllTemplates();
      // await this.mountFilters();
      await this.callAllMethodsFromReport();
    }, 1000);
  },
  computed: {
    dontHaveAllDatasToCards() {
      const has_all_datas = [
        this.totalSent || 0,
        this.totalSMSSent || 0,
        this.totalSMSClicks || 0,
        this.totalEmailSent || 0,
        this.totalEmailClicks || 0,
        this.totalEmailBounces || 0,
        this.totalEmailSpammed || 0,
        this.totalSpamed || 0,
        this.totalByChannel?.emails || 0,
        this.totalByChannel?.sms || 0,
        this.journeys ? this.journeys.length : 0,
        this.templates ? this.templates.length : 0,
      ];

      const is_all_zero = has_all_datas.every((el) => el <= 0);

      return is_all_zero;
    },
    hasAnyFilter() {
      return this.selectedInterval !== 1;
    },
    maxDate() {
      let MAX = "";

      if (this.filter.endsAt) MAX = this.filter.endsAt;
      else MAX = moment(moment.now()).format("YYYY-MM-DD");

      return MAX;
    },
    minimumDate() {
      let MIN = "";

      if (this.filter.startsAt) MIN = this.filter.startsAt;
      else MIN = moment(moment.now()).format("YYYY-MM-DD");

      return MIN;
    },
  },
  watch: {
    selectedJourneyInModal(nv) {
      if (this.selectedTemplateInJourney)
        this.orderCampaingTemplateMessage = `${nv?.name || ""}/${
          this.selectedTemplateInJourney?.name || ""
        }`;

      if (nv && !this.selectedTemplateInJourney)
        this.orderCampaingTemplateMessage = nv.name;

      if (!nv && this.selectedTemplateInJourney)
        this.orderCampaingTemplateMessage = this.selectedTemplateInJourney.name;

      if (!nv && !this.selectedTemplateInJourney)
        this.orderCampaingTemplateMessage = "";
    },
    selectedTemplateInJourney(nv) {
      if (this.selectedJourneyInModal)
        this.orderCampaingTemplateMessage = `${
          this.selectedJourneyInModal?.name || ""
        }/${nv?.name || ""}`;

      if (nv && !this.selectedJourneyInModal)
        this.orderCampaingTemplateMessage = nv.name;

      if (!nv && this.selectedJourneyInModal)
        this.orderCampaingTemplateMessage = this.selectedJourneyInModal.name;

      if (!nv && !this.selectedJourneyInModal)
        this.orderCampaingTemplateMessage = "";
    },
    tableName(nv) {
      if (nv && this.orderName) {
        this.orderJourneyMessage = `${nv.text}/${this.orderName.text}`;
      }
    },
    orderName(nv) {
      if (nv && this.tableName) {
        this.orderJourneyMessage = `${this.tableName.text}/${nv.text}`;
      }
    },
    tableTemplateName(nv) {
      if (nv && this.orderTempalteName) {
        this.orderTemplateMessage = `${nv.text}/${this.orderTempalteName.text}`;
      }
    },
    orderTempalteName(nv) {
      if (nv && this.tableTemplateName) {
        this.orderTemplateMessage = `${this.tableTemplateName.text}/${nv.text}`;
      }
    },
  },
  methods: {
    validateAndFormateNumber(num) {
      return num ? Number(num) : 0;
    },
    formatePercentToChips(dividend, divisor) {
      const dividend_formated = this.validateAndFormateNumber(dividend);
      const divisor_formated = this.validateAndFormateNumber(divisor);
      let percent = 0;

      if (dividend_formated > 0)
        percent = (dividend_formated / divisor_formated) * 100;

      return `(${percent.toFixed(2)}%)`;
    },
    formateNumberToLocal(num) {
      return num ? parseFloat(num).toLocaleString("pt-BR") : 0;
    },
    verifyDateAndFormate(date) {
      const is_valid = moment(date.trim(), "YYYY-MM-DD", true).isValid();

      const correct_date = is_valid
        ? date
        : moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
      return correct_date;
    },

    async resetFiltersAndReload() {
      this.filters = [];

      const INFO = this.intervalFilters.find((e) => e.value == 7);
      this.$store.dispatch("app/CREATE_DEFAULT_CHIP", INFO);
      this.filters = this.$store.getters["app/$chipsFilterList"];

      let dateInformation = this.filters.find(
        (chip) => chip.chip_type === "interval"
      );

      this.filter.endsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_end_date
      );

      this.filter.startsAt = this.verifyDateAndFormate(
        dateInformation.childs[0].chip_start_date
      );

      this.filter.selectedJourney = null;
      this.filter.selectedTemplate = null;

      await this.chooseGroupBy(dateInformation.childs[0].chip_info_child_id);

      this.callAllMethodsFromReport();
    },

    onRemoveAllChips() {
      this.resetFiltersAndReload();
    },

    async onUpdateChipList(e) {
      // caso o último chip seja apagado, necessário criar o chip default e chamar os métodos novamente
      if (!e.length) this.resetFiltersAndReload();
      else {
        this.filters = e;

        let journeysParents = e.filter((x) => x.chip_type === "journey");
        let templateParents = e.filter((x) => x.chip_type === "templates");
        let dateInformation = e.find((chip) => chip.chip_type === "interval");
        let orderInformation = e.filter((chip) => chip.chip_type == "order");

        this.filter.endsAt = this.verifyDateAndFormate(
          dateInformation.childs[0].chip_end_date
        );

        this.filter.startsAt = this.verifyDateAndFormate(
          dateInformation.childs[0].chip_start_date
        );

        if (journeysParents && journeysParents.length > 0) {
          this.filter.selectedJourney = journeysParents.flatMap((item) =>
            item.childs.map((child) => child.chip_info_child_id)
          );
        } else {
          this.filter.selectedJourney = null;
        }

        if (templateParents && templateParents.length > 0) {
          this.filter.selectedTemplate = templateParents.flatMap((item) =>
            item.childs.map((child) => child.chip_info_child_id)
          );
        } else {
          this.filter.selectedTemplate = null;
        }

        // ordenação proviniente dos chips

        if (orderInformation && orderInformation.length > 0) {
          // como só poderá ter um chip de cada classe(jornada ou template), eu pego diretamente o resultado da posição 0

          let template_chip_founded = orderInformation.flatMap((el) =>
            el.childs.find((c) => c.chip_assistant.includes("template"))
          )[0];
          let journey_chip_founded = orderInformation.flatMap((el) =>
            el.childs.find((c) => c.chip_assistant.includes("journey"))
          )[0];

          if (template_chip_founded) {
            let informations = template_chip_founded.chip_assistant.split("-");
            this.currentTemplateTableUrl = `reports/getTemplateReportsByCompanyId?sort=${informations[1]}&order=${informations[0]}`;
          } else
            this.currentTemplateTableUrl =
              "reports/getTemplateReportsByCompanyId";

          if (journey_chip_founded) {
            let informations = journey_chip_founded.chip_assistant.split("-");
            this.currentJourneyTableUrl = `reports/getJourneysReportsByCompanyId?sort=${informations[1]}&order=${informations[0]}`;
          } else
            this.currentJourneyTableUrl =
              "reports/getJourneysReportsByCompanyId";
        }

        await this.chooseGroupBy(dateInformation.childs[0].chip_info_child_id);
        this.callAllMethodsFromReport();
      }
    },

    goToRoute(name, id) {
      this.$router.push({ name, params: { id } });
    },

    drillUpGraph(type) {
      const _ = require("lodash");

      if (type == "send") {
        let INDEX;

        for (
          let idx = 0;
          idx < this.sendChartDataInformationArr.length;
          idx++
        ) {
          if (
            _.isEqual(
              this.sendChartDataInformationArr[idx],
              this.sendChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.sendChartDataInformation =
            this.sendChartDataInformationArr[INDEX - 1];
          this.sendChartDataInformationArr.splice(INDEX);
        }
      }
      if (type == "engage") {
        let INDEX;

        for (
          let idx = 0;
          idx < this.engageChartDataInformationArr.length;
          idx++
        ) {
          if (
            _.isEqual(
              this.engageChartDataInformationArr[idx],
              this.engageChartDataInformation
            )
          ) {
            INDEX = idx;
          }
        }

        if (INDEX - 1 >= 0) {
          this.engageChartDataInformation =
            this.engageChartDataInformationArr[INDEX - 1];
          this.engageChartDataInformationArr.splice(INDEX);
        }
      }
    },

    async getChartClickedInfo(chart, e, type) {
      this.isFilteringGraph = true;
      const points = chart.getElementsAtEventForMode(
        e,
        "nearest",
        {
          intersect: true,
        },
        true
      );
      if (points.length) {
        //  const dataset = points[0].datasetIndex;
        // const CURRENT_CLICKED_VALUE = chart.data.datasets[dataset].data[index];
        const index = points[0].index;
        const CURRENT_CLICKED_LABEL = chart.data.labels[index];
        const LABELS_INFO = CURRENT_CLICKED_LABEL.split("/");

        const MONTHS = [
          "jan",
          "fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez",
        ];

        // ------- MÊS >> PARA >> DIA -------------------------
        if (MONTHS.includes(LABELS_INFO[0])) {
          const START_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .startOf("month")
            .format("YYYY-MM-DD");
          const END_MONTH = moment(CURRENT_CLICKED_LABEL, "MMM/YYYY")
            .endOf("month")
            .format("YYYY-MM-DD");

          // TROCAR O FILTRO PARA CORRESPONDER COM DAY OU MONTH
          // TROCAR O FILTRO PARA PEGAR O START E O END ATUAL DO ELEMENTO CLICADO...(PRIMEIRO DIA DO MÊS/ULTIMO DIA DO MÊS OU DATA ATUAL)
          this.filter.groupBy = "day";
          this.filter.startsAt = START_MONTH;
          this.filter.endsAt = END_MONTH;

          if (type == "send")
            await this.monthToDaySendChart(START_MONTH, END_MONTH, "day");

          if (type == "engage")
            await this.monthToDayEngageChart(START_MONTH, END_MONTH, "day");
        }

        // ------- DIA >>>> HORA -------------------------
        if (+LABELS_INFO[0] >= 0 && !CURRENT_CLICKED_LABEL.includes(":")) {
          this.isFilteringHour = true;
          const t = `${LABELS_INFO[2]}-${LABELS_INFO[1]}-${LABELS_INFO[0]}`;
          const START = moment(t).format("YYYY-MM-DD");
          const END = moment(t).add(1, "day").format("YYYY-MM-DD");

          if (type == "send")
            await this.monthToDaySendChart(START, END, "hour");
          if (type == "engage")
            await this.monthToDayEngageChart(START, END, "hour");

          this.isFilteringHour = false;
        }
      }

      this.isFilteringGraph = false;

      return;

      // chart.config.data = mock;
      // chart.update();
    },

    doughnutChartCancel() {
      this.filter.channel = "";
      this.callAllMethodsFromReport();
    },

    doughnutChartClicked(info) {
      // INSERIR CHANNEL NO FILTRO E CHAMAR TODAS AS REQUISIÇÕES DAS TELAS NOVAMENTE
      this.filter.channel = info;
      this.callAllMethodsFromReport();
    },

    async monthToDaySendChart(start, end, type) {
      this.sendChartDataInformationLoading = true;
      const INFO = await this.getSentQuantityToChart(start, end, type);

      if (INFO) {
        this.sendChartDataInformation = INFO;

        this.sendChartDataInformationArr.push(INFO);
      }
      this.sendChartDataInformationLoading = false;

      return;
    },

    async monthToDayEngageChart(start, end, type) {
      this.engageChartDataInformationLoading = true;

      const INFO = await this.getEngageQuantityToChart(start, end, type);

      if (INFO) {
        this.engageChartDataInformation = INFO;
        this.engageChartDataInformationArr.push(INFO);
      }

      this.engageChartDataInformationLoading = false;

      return;
    },

    async downloadDocument() {
      try {
        this.loadingDownloadJourneys = true;

        const filter = {
          ...this.filter,
          ...this.templateTableFilter,
        };

        const response = await this.http.post(
          "reports/exportJourneysReportsByCompanyId",
          filter
        );

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "journeys.csv";
        anchor.click();

        this.loadingDownloadJourneys = false;
      } catch {
        this.loadingDownloadJourneys = false;
        this.$toast.error("Ocorreu um erro ao exportar os dados!");
      }
    },
    async downloadDocumentTemplates() {
      try {
        this.loadingDownloadTemplates = true;

        const filter = {
          ...this.filter,
          ...this.templateTableFilter,
        };

        const response = await this.http.post(
          "reports/exportTemplateReportsByCompanyId",
          filter
        );

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "templates.csv";
        anchor.click();

        this.loadingDownloadTemplates = false;
      } catch {
        this.loadingDownloadTemplates = false;
        this.$toast.error("Ocorreu um erro ao exportar os dados!");
      }
    },

    mountLabelsMonth(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          DATES_ARR.push(start.format("MMM/YYYY"));
          start.add(1, "month");
        }
      }

      return DATES_ARR;
    },

    mountLabelsDays(start, end) {
      moment.locale("pt-br");
      const DATES_ARR = [];

      if (!end.isSameOrBefore(start)) {
        while (start.isSameOrBefore(end)) {
          // DATES_ARR.push(start.format("DD/MMM"));
          DATES_ARR.push(start.format("DD/MM/YYYY"));
          start.add(1, "day");
        }
      }

      return DATES_ARR;
    },

    mountLabelsHour() {
      const DATES_ARR = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];

      return DATES_ARR;
    },

    verifyCustomDate() {
      const start = moment(this.filter.startsAt, "YYYY-MM-DD");
      const end = moment(this.filter.endsAt, "YYYY-MM-DD");
      const diference = end.diff(start, "days");

      if (diference <= 30) this.filter.groupBy = "day";
      else this.filter.groupBy = "month";

      return;
    },
    async chooseGroupBy(info = 7) {
      switch (info) {
        case 1:
          this.filter.groupBy = "month";
          break;
        case 2:
          this.filter.groupBy = "month";
          break;
        case 3:
          this.filter.groupBy = "month";
          break;
        case 4:
          this.filter.groupBy = "day";
          break;
        case 5:
          this.filter.groupBy = "day";
          break;
        case 6:
          await this.verifyCustomDate();
          break;
        case 7:
          this.filter.groupBy = "day";
          break;
      }

      this.selectedInterval = info;

      return;
    },

    // mountFilters() {
    //   let DATES = null;

    //   switch (this.selectedInterval) {
    //     case 1:
    //       DATES = this.getLastTwelveMonthsUltilDate().split("até");
    //       this.filter.groupBy = "month";
    //       break;
    //     case 2:
    //       DATES = this.getYearUntilDate().split("até");
    //       this.filter.groupBy = "month";
    //       break;
    //     case 3:
    //       DATES = this.getAllLastYear().split("até");
    //       this.filter.groupBy = "month";
    //       break;
    //     case 4:
    //       DATES = this.getMonthUntilToday().split("até");
    //       this.filter.groupBy = "day";
    //       break;
    //     case 5:
    //       DATES = this.getLastMonth().split("até");
    //       this.filter.groupBy = "day";
    //       break;
    //   }

    //   if (this.selectedInterval < 6) {
    //     const START = DATES[0].trim().split("-");
    //     const END = DATES[1].trim().split("-");

    //     this.filter.startsAt = `${START[2]}-${START[1]}-${START[0]}`;
    //     this.filter.endsAt = `${END[2]}-${END[1]}-${END[0]}`;
    //   } else {
    //     this.filter.startsAt = "";
    //     this.filter.endsAt = "";
    //   }
    //   return;
    // },

    actualizeTab(tab) {
      this.actualTab = tab;
    },

    getAllLastYear() {
      const START_YEAR_DATE = moment()
        .subtract(1, "year")
        .startOf("year")
        .format("DD-MM-YYYY");

      const END_YEAR_DATE = moment(START_YEAR_DATE)
        .endOf("year")
        .format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${END_YEAR_DATE}`;

      return S;
    },

    getYearUntilDate() {
      const START_YEAR_DATE = moment().startOf("year").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_YEAR_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastTwelveMonthsUltilDate() {
      const START = moment().subtract(12, "months").format("DD-MM-YYYY");
      const END = moment().format("DD-MM-YYYY");

      return `${START} até ${END}`;
    },

    getMonthUntilToday() {
      const START_MONTH_DATE = moment().startOf("month").format("DD-MM-YYYY");
      const ACTUAL_DATE = moment().format("DD-MM-YYYY");
      const S = `${START_MONTH_DATE} até ${ACTUAL_DATE}`;
      return S;
    },

    getLastMonth() {
      const START_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("DD-MM-YYYY");

      const END_LAST_MONTH_DATE = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("DD-MM-YYYY");

      return `${START_LAST_MONTH_DATE} até ${END_LAST_MONTH_DATE}`;
    },
    getLasThirtyDays() {
      const START_DATE = moment().subtract(30, "days").format("DD-MM-YYYY");

      const END_DATE = moment().format("DD-MM-YYYY");

      return `${START_DATE} até ${END_DATE}`;
    },

    async callAllMethodsFromReport() {
      this.isLoading = true;

      await this.getChipValues();
      
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      
      const tableData = [
        await this.getCompanyUseData(),
        this.getJourneysReportsByCompanyId(),
        this.getTemplateReportsByCompanyId(),
      ];

      await Promise.all(tableData);

      await this.getChartsData();
    },

    async filterReports() {
      if (this.selectedInterval !== 6) await this.callAllMethodsFromReport();
      else if (this.$refs.form.validate() && this.pickerRange)
        await this.callAllMethodsFromReport();
    },

    formatDate(date) {
      const DATE = moment(date).format("DD/MM/YYYY HH:mm");
      return DATE == "Invalid date" || DATE == "Data inválida"
        ? "Sem informação"
        : DATE;
    },

    formatHour(date) {
      return moment(date).format("HH:mm");
    },

    async getCompanyUseData() {
      try {
        this.consumeChardDataInformationLoading = true;
        let resp = await this.http.post(
          "reports/getCompanyUsageData",
          this.filter
        );

        console.log(resp);

        // alterar para receber o valor vindo da api
        let obj = await this.mountConsumeGraphObject(resp);
        this.consumeChardDataInformation = obj;

        this.consumeChardDataInformationLoading = false;
      } catch (e) {
        console.log(e);
        this.consumeChardDataInformationLoading = false;
      }
    },

    async getChipValues() {
      try {
        let resp = await this.http.post("reports/getChipValues", this.filter);

        this.totalSent = resp.total;
        this.totalClicks = resp.totalClicks;
        this.pushInfosCards = resp?.push;
        let emailValues = resp.email;

        this.totalByChannel = {};

        if (emailValues) {
          this.totalEmailSent = emailValues.count_total;
          this.totalEmailDelivery = emailValues.count_delivered;
          this.totalEmailInjected = emailValues.count_injected;
          this.totalEmailOpens = emailValues.count_opened;
          this.totalEmailClicks = emailValues.count_clicked;
          this.totalEmailBounces = emailValues.count_bounced;
          this.totalEmailSpammed = emailValues.count_spam;

          this.totalByChannel.emails = emailValues.count_total;
        }

        let smsValues = resp.sms;

        if (smsValues) {
          this.totalSMSSent = smsValues.count_total;
          this.totalSMSClicks = smsValues.count_clicked;
          this.totalByChannel.sms = smsValues.count_total;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getAllJourneys() {
      this.http
        .post("reports/getAllJourneys", this.filter)
        .then((resp) => {
          this.journeyOptions = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllTemplates() {
      this.http
        .post("reports/getAllTemplates", this.filter)
        .then((resp) => {
          this.templateOptions = resp;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async getTotalOptOuts() {
      let filterLocal = { ...this.filter };
      this.http
        .post("reports/getTotalOptOuts", filterLocal)
        .then((resp) => {
          this.totalOptOuts = resp.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getChartsData() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        selectedTemplate: this.filter?.selectedTemplate,
      };

      try {
        this.sendChartDataInformationLoading = true;
        this.engageChartDataInformationLoading = true;
        const response = await this.http.post("reports/getChartsData", FILTER);
        this.sendChartDataInformationArr = [];

        // const mock = {
        //   sent: [1, 2, 3, 4, 5, 6, 7,8 , 9, 10],
        //   opened: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        //   clicked: [54, 34, 5, 6, 3, 5,6, 8, 9,34],
        // };

        this.sendChartDataInformation = await this.mountSentGraphObject(
          response.sentQty
        );

        this.sendChartDataInformationArr.push(this.sendChartDataInformation);

        this.engageChartDataInformationArr = [];

        // const mock = {
        //   clickRate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        //   openRate: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        // };

        this.engageChartDataInformation = await this.mountEngageGraphObject(
          response.rate
        );
        this.engageChartDataInformationArr.push(
          this.engageChartDataInformation
        );

        this.sendChartDataInformationLoading = false;
        this.engageChartDataInformationLoading = false;
      } catch (error) {
        console.error(error);
        this.sendChartDataInformationLoading = false;
        this.engageChartDataInformationLoading = false;
      }
    },

    async getSentQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        selectedTemplate: this.filter?.selectedTemplate,
      };
      try {
        const response = await this.http.post(
          "reports/getSentQuantityChartData",
          FILTER
        );
        this.sendChartDataInformationArr = [];

        // const mock = {
        //   sent: [1, 2, 3, 4, 5, 6, 7,8 , 9, 10],
        //   opened: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        //   clicked: [54, 34, 5, 6, 3, 5,6, 8, 9,34],
        // };

        this.sendChartDataInformation = await this.mountSentGraphObject(
          response
        );

        this.sendChartDataInformationArr.push(this.sendChartDataInformation);
      } catch (error) {
        console.error(error);
      }
    },

    async getSentQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        selectedTemplate: this.filter?.selectedTemplate,
      };

      let INFO = null;
      try {
        const response = await this.http.post(
          "reports/getSentQuantityChartData",
          FILTER
        );
        INFO = await this.mountSentGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    async getEngageQuantity() {
      const FILTER = {
        startDate: this.filter.startsAt,
        endDate: this.filter.endsAt,
        groupBy: this.filter.groupBy,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
        selectedTemplate: this.filter?.selectedTemplate,
      };
      try {
        const response = await this.http.post(
          "reports/getRateChartData",
          FILTER
        );
        this.engageChartDataInformationArr = [];

        // const mock = {
        //   clickRate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        //   openRate: [4, 5, 6, 7, 8, 9, 0, 12, 24, 45],
        // };

        this.engageChartDataInformation = await this.mountEngageGraphObject(
          response
        );
        this.engageChartDataInformationArr.push(
          this.engageChartDataInformation
        );
      } catch (error) {
        console.error(error);
      }
    },

    async getEngageQuantityToChart(start, end, group) {
      const FILTER = {
        startDate: start,
        endDate: end,
        groupBy: group,
        channel: this.filter?.channel,
        selectedJourney: this.filter?.selectedJourney,
      };
      let INFO = null;

      try {
        const response = await this.http.post(
          "reports/getRateChartData",
          FILTER
        );
        INFO = await this.mountEngageGraphObject(response);
      } catch (error) {
        console.error(error);
      }

      return INFO;
    },

    async getJourneysReportsByCompanyId() {
      this.loadingJourneys = true;
      const URL = this.currentJourneyTableUrl
        ? this.currentJourneyTableUrl
        : "reports/getJourneysReportsByCompanyId";

      const filter = {
        ...this.filter,
        ...this.journeyTableFilter,
      };

      this.http
        .post(URL, filter)
        .then((resp) => {
          this.journeys = [...resp.items];
          this.journeyTableFilter.page = resp.page;
          this.journeyTableFilter.totalPages = resp.pages;
          this.loadingJourneys = false;
        })
        .catch((err) => {
          this.loadingJourneys = false;
          console.log(err);
        });
    },

    async getTemplateReportsByCompanyId() {
      this.loadingTemplates = true;
      const URL = this.currentTemplateTableUrl
        ? this.currentTemplateTableUrl
        : "reports/getTemplateReportsByCompanyId";

      const filter = {
        ...this.filter,
        ...this.templateTableFilter,
      };

      this.http
        .post(URL, filter)
        .then((resp) => {
          this.templates = [...resp.items];
          this.templateTableFilter.page = resp.page;
          this.templateTableFilter.totalPages = resp.pages;
          this.loadingTemplates = false;
        })
        .catch((err) => {
          this.loadingTemplates = false;
          console.log(err);
        });
    },

    async getSortedTemplateReportsByCompanyId(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }
      const URL = `reports/getTemplateReportsByCompanyId?sort=${name}&order=${ORDER}`;
      this.currentTemplateTableUrl = URL;

      try {
        this.loadingSortTableTemplate = true;
        const resp = await this.http.post(URL, this.filter);
        this.templates = [...resp.items];
        this.templateTableFilter.page = resp.page;
        this.templateTableFilter.totalPages = resp.pages;
        this.loadingSortTableTemplate = false;
      } catch (error) {
        this.$toast.error("Erro ao realizar ordenação");
        console.error(error);
        this.loadingSortTableTemplate = false;
      }
    },

    orderByModal(modalSelected) {
      if (modalSelected) {
        this.getSortedTemplateReportsByCompanyId(
          this.tableTemplateName.value || "",
          this.orderTempalteName.value || ""
        );
      } else {
        this.getSortedJourneysReportsByCompanyId(
          this.tableName.value || "",
          this.orderName.value || "DESC"
        );
      }

      this.closeOnContentClick = true;
      setTimeout(() => {
        this.closeOnContentClick = false;
      }, 300);
    },

    async goNextPageTemplatesReportsByCompanyId() {
      this.templateTableFilter.page++;
      await this.getTemplateReportsByCompanyId();
    },

    async backPreviousPageTemplatesReportsByCompanyId() {
      this.templateTableFilter.page--;
      await this.getTemplateReportsByCompanyId();
    },

    async goNextPageJourneysReportsByCompanyId() {
      this.journeyTableFilter.page++;
      await this.getJourneysReportsByCompanyId();
    },

    async backPreviousPageJourneysReportsByCompanyId() {
      this.journeyTableFilter.page--;
      await this.getJourneysReportsByCompanyId();
    },

    async getSortedJourneysReportsByCompanyId(name, order) {
      let ORDER = null;

      if (order != null) ORDER = order;
      else {
        this.sortDesc = !this.sortDesc;
        ORDER = this.sortDesc ? "ASC" : "DESC";
      }
      const URL = `reports/getJourneysReportsByCompanyId?sort=${name}&order=${ORDER}`;
      this.currentJourneyTableUrl = URL;

      try {
        this.loadingSortTable = true;
        const resp = await this.http.post(URL, this.filter);
        this.journeys = resp.items;
        this.journeyTableFilter.page = resp.page;
        this.journeyTableFilter.totalPages = resp.pages;
        this.loadingSortTable = false;
      } catch (error) {
        this.$toast.error("Erro ao realizar ordenação");
        console.error(error);
        this.loadingSortTable = false;
      }
    },

    async chooseHowMountLabel(start, end, infoLength) {
      let LABELS = [];
      if (this.selectedInterval >= 4 || this.isFilteringGraph) {
        if (this.isFilteringHour) {
          LABELS = await this.mountLabelsHour();
        } else if (infoLength > 1 && this.filter.groupBy == "day") {
          LABELS = await this.mountLabelsDays(start, end);
        } else LABELS = await this.mountLabelsMonth(start, end);
      }

      this.selectedInterval < 4 &&
      !this.isFilteringGraph &&
      !this.isFilteringHour
        ? (LABELS = await this.mountLabelsMonth(start, end))
        : "";

      return LABELS || [];
    },

    async mountEngageGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.clickRate.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      // datasets[1] = {
      //   data: info.clickRate,
      //   label: "Clicados",
      //   backgroundColor: "#00BBD3",
      // };

      // datasets[0] = { data: info.openRate, label: "Abertos", backgroundColor: "#B2D2D6" };

      datasets[1] = {
        data: info.clickRate,
        label: "Clicados",
        backgroundColor: "#F6BD02",
      };

      datasets[0] = {
        data: info.openRate,
        label: "Abertos",
        backgroundColor: "rgba(255, 143, 40, 1)",
      };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountSentGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);
      const LENGTH = info.sent.length;
      const LABELS = await this.chooseHowMountLabel(START, END, LENGTH);

      // datasets[0] = { data: info.opened, label: "Abertos", backgroundColor: "#B2D2D6" };
      // datasets[1] = { data: info.clicked, label: "Clicados", backgroundColor: "#00BBD3" };
      // datasets[2] = { data: info.sent, label: "Enviados", backgroundColor: "#004C91" };

      datasets[1] = {
        data: info.opened,
        label: "Abertos",
        backgroundColor: "rgba(255, 143, 40, 1)",
      };
      datasets[2] = {
        data: info.clicked,
        label: "Clicados",
        backgroundColor: "#F6BD02",
      };
      datasets[3] = {
        data: info.bounced,
        label: "Bounce",
        backgroundColor: "#a6111b",
      };
      datasets[0] = {
        data: info.sent,
        label: "Enviados",
        backgroundColor: "rgb(222, 87, 66)",
      };

      chartData.labels = LABELS;
      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    async mountConsumeGraphObject(info) {
      moment.locale("pt-br");
      let chartData = {};
      let datasets = [];
      const START = moment(this.filter.startsAt);
      const END = moment(this.filter.endsAt);

      let daydiff = END.diff(START, "days");

      datasets[0] = {
        data: [],
        label: "Consumo",
        backgroundColor: "rgba(255, 143, 40, 1)",
      };

      let dates = [];
      for (let i = 0; i <= daydiff; i++) {
        let currentDate = moment(this.filter.startsAt).add(i, "days");

        dates.push(currentDate);
      }

      chartData.labels = [];
      for (let i = 0; i < dates.length; i++) {
        chartData.labels.push(dates[i].format("DD/MM/yyyy"));

        let emailData = info.find(
          (x) => x._id === dates[i].format("yyyy-MM-DD")
        );

        if (emailData) datasets[0].data.push(emailData.count);
        else datasets[0].data.push(0);
      }

      chartData.datasets = datasets;

      chartData;
      return chartData;
    },

    // async getOpenRateByDateRange() {
    //   this.http
    //     .post("reports/getOpenRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.openRateByDateRange = resp;
    //       this.opensData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // async getClickRateByDateRange() {
    //   this.http
    //     .post("reports/getClickRateByDateRange", this.filter)
    //     .then((resp) => {
    //       this.clickRateByDateRange = resp;
    //       this.clicksData = this.treatDataRange(resp);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // treatDataRange(arr) {
    //   let temp = [];
    //   for (let index = 0; index < 12; index++) {
    //     const i = arr.findIndex((item) => parseInt(item.month) === index + 1);
    //     if (i === -1) {
    //       temp.push(0);
    //     } else {
    //       temp.push(parseInt(arr[i].count));
    //     }
    //   }
    //   return temp;
    // },

    // getFiltersInformationsByModal() {
    //   this.filter.selectedJourney = this.selectedJourneyInModal?.id || "";
    //   this.filter.selectedTemplate = this.selectedTemplateInJourney?.id || "";

    //   this.filterReports();

    //   this.closeOnContentClick = true;
    //   setTimeout(() => {
    //     this.closeOnContentClick = false;
    //   }, 300);
    // },

    // setValuePickerToFilter(evt) {
    //   this.filter.startsAt = evt[0];
    //   this.filter.endsAt = evt[evt.length - 1];
    // },

    // cleanOrderModal() {
    //   this.tableName = "";
    //   this.orderName = "";
    //   this.orderJourneyMessage = "";
    //   this.orderByModal(0);
    // },

    // clearFitlerModal() {
    //   this.orderCampaingTemplateMessage = "";
    //   this.selectedJourneyInModal = "";
    //   this.selectedTemplateInJourney = "";
    //   this.filter.selectedJourney = "";
    //   this.filter.selectedTemplate = "";
    //   this.filterReports();
    // },
    // cleanOrderModalTemplates() {
    //   this.tableTemplateName = "";
    //   this.orderTempalteName = "";
    //   this.orderTemplateMessage = "";
    //   this.orderByModal(1);
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.icon_card_report {
  height: 50px;
  width: 50px;
  background-color: rgb(246, 189, 2);
  border-radius: 30px;
}

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    position: relative;

    &__overlay {
      position: absolute;
      inset: 0;
      background: rgba(248, 245, 246, 0.8);
      text-align: center;
      z-index: 5;

      .message {
        background: #666666;
        color: white;
        max-width: 60%;
        margin: 0 auto;
        margin-top: 10rem;
        padding: 1rem;
        border-radius: 10px;
        span {
          font-weight: 600;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;

      .left {
        flex: 1 1 auto;

        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }

      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;

          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;

      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $z-s-2;

        .metric-type {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 100%;
          }
          small {
            font-weight: 500;
          }
        }
      }

      &__cards {
        .item {
          position: relative;

          .dot {
            position: absolute;

            width: 8px;
            height: 8px;
            border-radius: 100% !important;
            top: 10px;
            left: 10px;
          }
        }

        @media (max-width: 1300px) {
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
      }

      // overflow-y: auto;
      // @include trackScrollBar;
      .table-wrapper {
        height: 450px !important;
        max-height: 500px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
          height: 250px;
        }

        @media (min-width: 1400px) {
          height: auto !important;
          max-height: 650px;
        }
      }

      // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;
              }

              &:last-child {
                text-align: right !important;
              }
            }

            td {
              width: 33px;
              max-width: 33px;
              &:not(:first-child) {
                text-align: center !important;

                & > div,
                span {
                  margin: 0 auto !important;
                }
              }

              &:last-child {
                text-align: right !important;
              }

              & > div,
              span {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }

    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;

  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;

    span {
      font-weight: 500;
    }

    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;

  &__title {
    text-align: center;
    font-weight: 500;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
