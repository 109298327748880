import { render, staticRenderFns } from "./Doughnut.vue?vue&type=template&id=44d5d6c0&"
import script from "./Doughnut.vue?vue&type=script&lang=js&"
export * from "./Doughnut.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kalil/Documents/projects/zoox/data-to-act-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('44d5d6c0')) {
      api.createRecord('44d5d6c0', component.options)
    } else {
      api.reload('44d5d6c0', component.options)
    }
    module.hot.accept("./Doughnut.vue?vue&type=template&id=44d5d6c0&", function () {
      api.rerender('44d5d6c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Pages/Reports/components/Doughnut.vue"
export default component.exports