var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "padding-top": "1rem" } }, [
    _c("div", { staticClass: "reports_filter px-5 mb-8" }, [
      _c(
        "div",
        { staticClass: "px-4 py-4" },
        [
          _c("z-filter", {
            attrs: {
              "interval-filters-list-prop": _vm.intervalFilters,
              "tempaltes-list-prop": _vm.templates,
              "journeys-list-prop": _vm.journeyOptions,
              "filter-options-prop": _vm.itemsFilterList,
            },
            on: {
              "chip-created": _vm.onUpdateChipList,
              "remove-all-chips": _vm.onRemoveAllChips,
              "remove-single-chip": _vm.onUpdateChipList,
              "update-chip": _vm.onUpdateChipList,
              "remove-child-chip": _vm.onUpdateChipList,
            },
          }),
        ],
        1
      ),
    ]),
    !_vm.isLoading
      ? _c("div", { staticClass: "wrapper__card mx-5" }, [
          _c(
            "div",
            { staticClass: "wrapper__card__body" },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "px-3 mb-3 d-flex align-center justify-space-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper__card__body__header" },
                    _vm._l(_vm.metricsTypes, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id + item.type,
                          staticClass: "metric-type mb-2",
                        },
                        [
                          _c("div", {
                            staticClass: "dot",
                            style: { background: item.color },
                          }),
                          _c("small", { style: { color: item.color } }, [
                            _vm._v(_vm._s(item.type)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    [
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: {
                          rounded: "",
                          primary: "",
                          text: "Extração de dados",
                          icon: "$upload_file",
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(`/dataExtraction/company`)
                          },
                        },
                      }),
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: {
                          rounded: "",
                          primary: "",
                          text: "Consumo de Creditos",
                          icon: "$mdi-currency-usd",
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(`/usage`)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "wrapper__card__body__cards" },
                [
                  _c(
                    "v-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [_vm._v("Metricas Gerais")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$send",
                              "main-color-prop": "rgb(246, 189, 2)",
                              "border-color-prop": "#ffce4d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL ENVIOS ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formateNumberToLocal(
                                            _vm.totalSent
                                          ) || 0
                                        )
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              172496297
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [_vm._v("Metricas SMS")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$send",
                              "main-color-prop": "rgb(246, 189, 2)",
                              "border-color-prop": "#30895e",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL ENVIOS SMS ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formateNumberToLocal(
                                            _vm.totalSMSSent
                                          ) || 0
                                        )
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4289650409
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$arrow_back",
                              "main-color-prop": "rgba(73, 203, 140, 1)",
                              "border-color-prop": "#30895e",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL CLIQUES SMS ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalSMSClicks
                                            ) || 0
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalSMSClicks,
                                              _vm.totalSMSSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              306545266
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [_vm._v("Metricas Email")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$send",
                              "main-color-prop": "rgb(246, 189, 2)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL ENVIOS EMAIL")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formateNumberToLocal(
                                            _vm.totalEmailSent
                                          ) || 0
                                        )
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4108232361
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-email-open-outline",
                              "main-color-prop": "rgb(105, 188, 203)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL ENTREGUE EMAIL")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalEmailDelivery
                                            ) || 0
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalEmailDelivery,
                                              _vm.totalEmailSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3945735545
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-email-open-outline",
                              "main-color-prop": "rgb(105, 188, 203)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL ABERTURAS EMAIL")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalEmailOpens
                                            ) || 0
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalEmailOpens,
                                              _vm.totalEmailSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3794313299
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$arrow_back",
                              "main-color-prop": "rgba(73, 203, 140, 1)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL CLIQUES EMAIL")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalEmailClicks
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalEmailClicks,
                                              _vm.totalEmailSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3381983874
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-email-sync-outline",
                              "main-color-prop": "rgb(100, 45, 108)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL BOUNCES EMAIL")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalEmailBounces
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalEmailBounces,
                                              _vm.totalEmailSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3861034389
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-alert-octagon-outline",
                              "main-color-prop": "rgb(222, 87, 66)",
                              "border-color-prop": "#411e6d",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL SPAM EMAIL ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.totalEmailSpammed
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.totalEmailSpammed,
                                              _vm.totalEmailSent
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2987854681
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { width: "100%" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [_vm._v("Metricas Push")]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-alert-octagon-outline",
                              "main-color-prop": "rgb(246, 189, 2)",
                              "border-color-prop": "#53A9D8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" TOTAL DE ENVIOS ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.pushInfosCards?.count_total
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1092552317
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-email-open-outline",
                              "main-color-prop": "rgb(105, 188, 203)",
                              "border-color-prop": "#53A9D8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" ABERTURAS PUSH ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.pushInfosCards?.count_opened
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.pushInfosCards?.count_opened,
                                              _vm.pushInfosCards?.count_total
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              719608186
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "$arrow_back",
                              "main-color-prop": "rgba(73, 203, 140, 1)",
                              "border-color-prop": "#53A9D8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" CLIQUES PUSH ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.pushInfosCards?.count_opened
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.pushInfosCards?.count_clicked,
                                              _vm.pushInfosCards?.count_total
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1081111905
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-email-sync-outline",
                              "main-color-prop": "rgb(100, 45, 108)",
                              "border-color-prop": "#53A9D8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" BOUNCES PUSH ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.pushInfosCards?.count_opened
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.pushInfosCards?.count_spam,
                                              _vm.pushInfosCards?.count_total
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1927886614
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("CardReport", {
                            attrs: {
                              icon: "mdi-alert-octagon-outline",
                              "main-color-prop": "rgb(222, 87, 66)",
                              "border-color-prop": "#53A9D8",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v(" SPAM PUSH ")]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.pushInfosCards?.count_opened
                                            )
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.formatePercentToChips(
                                              _vm.pushInfosCards?.count_spam,
                                              _vm.pushInfosCards?.count_total
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1765720986
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        lg: "8",
                        md: "8",
                        sm: "12",
                        xs: "12",
                        cols: "12",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c("z-tabs", {
                            attrs: { android: "", tabs: _vm.tabs },
                            on: { change: _vm.actualizeTab },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "send",
                                  fn: function () {
                                    return [
                                      _c(
                                        "section",
                                        [
                                          _c(
                                            "aside",
                                            {
                                              staticClass: "d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm
                                                          .sendChartDataInformationArr
                                                          .length > 1,
                                                      expression:
                                                        "sendChartDataInformationArr.length > 1",
                                                    },
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.drillUpGraph(
                                                        "send"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "$arrow_upward_rounded"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.sendChartDataInformationLoading
                                            ? _c("BarChart", {
                                                attrs: {
                                                  "chart-data":
                                                    _vm.sendChartDataInformation,
                                                  "chart-options":
                                                    _vm.sendChartOptions,
                                                },
                                              })
                                            : _c(
                                                "aside",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-center",
                                                  staticStyle: {
                                                    height: "320px",
                                                  },
                                                },
                                                [_c("z-loading")],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "engage",
                                  fn: function () {
                                    return [
                                      _c(
                                        "section",
                                        [
                                          _c(
                                            "aside",
                                            {
                                              staticClass: "d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm
                                                          .engageChartDataInformationArr
                                                          .length > 1,
                                                      expression:
                                                        "engageChartDataInformationArr.length > 1",
                                                    },
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.drillUpGraph(
                                                        "engage"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "$arrow_upward_rounded"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.engageChartDataInformationLoading
                                            ? _c("BarChart", {
                                                attrs: {
                                                  "chart-options":
                                                    _vm.engageChartOptions,
                                                  "chart-data":
                                                    _vm.engageChartDataInformation,
                                                },
                                              })
                                            : _c(
                                                "aside",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-center",
                                                  staticStyle: {
                                                    height: "320px",
                                                  },
                                                },
                                                [_c("z-loading")],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3142803290
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        lg: "4",
                        md: "4",
                        sm: "12",
                        xs: "12",
                        cols: "12",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("CANAL")]
                              ),
                              _vm.filter.channel
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "#004C91" },
                                      on: { click: _vm.doughnutChartCancel },
                                    },
                                    [_vm._v("$settings_backup_restore")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.totalByChannel
                            ? _c("DoughnutChart", {
                                attrs: {
                                  labels: ["Email", "SMS"],
                                  totals: _vm.totalByChannel,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _vm.consumeChardDataInformation
                            ? _c("BarChart", {
                                attrs: {
                                  "chart-data": _vm.consumeChardDataInformation,
                                  "chart-options": _vm.sendChartOptions,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-5 py-4 d-flex justify-space-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("TABELA DE JORNADAS")]),
                              _c("z-btn", {
                                attrs: {
                                  small: "",
                                  rounded: "",
                                  primary: "",
                                  text: "",
                                  icon: `mdi-cloud-download`,
                                  "is-loading": _vm.loadingDownloadJourneys,
                                },
                                on: { click: _vm.downloadDocument },
                              }),
                            ],
                            1
                          ),
                          !_vm.loadingJourneys
                            ? _c("z-pagination", {
                                attrs: {
                                  "auto-pagination": false,
                                  "icon-previous": "mdi-chevron-left",
                                  "icon-next": "mdi-chevron-right",
                                  "items-per-page": 10,
                                  items: _vm.journeys || [],
                                  "current-page": _vm.journeyTableFilter.page,
                                  "number-of-pages":
                                    _vm.journeyTableFilter.totalPages,
                                },
                                on: {
                                  "click:next":
                                    _vm.goNextPageJourneysReportsByCompanyId,
                                  "click:previous":
                                    _vm.backPreviousPageJourneysReportsByCompanyId,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "data",
                                      fn: function ({ paginatedItems }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "table-wrapper",
                                              staticStyle: {
                                                height: "550px !important",
                                                "max-height":
                                                  "550px !important",
                                              },
                                            },
                                            [
                                              _c("z-table", {
                                                staticClass: "table",
                                                attrs: {
                                                  headers: _vm.headers,
                                                  items: paginatedItems,
                                                  "icon-next":
                                                    "mdi-chevron-right",
                                                  "icon-previous":
                                                    "mdi-chevron-left",
                                                  "is-loading":
                                                    _vm.loadingSortTable,
                                                },
                                                on: {
                                                  sort: function ($event) {
                                                    return _vm.getSortedJourneysReportsByCompanyId(
                                                      $event
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "no-data",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "no-data-table d-flex align-end justify-center",
                                                            },
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Sem dados disponíveis."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: `item.dateCreated`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  item.dateCreated
                                                                )
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.status`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.status ===
                                                                  "active"
                                                                  ? "Ativa"
                                                                  : "Finalizada"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.journeyType`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.journeyType
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.nextUpdate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.status ===
                                                                  "active"
                                                                  ? _vm.formatDate(
                                                                      item.nextUpdate
                                                                    ) || "-"
                                                                  : "-"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.clicked`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.clicked ||
                                                                  "0"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.opened`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.opened ||
                                                                  "0"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.sent`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.sent || "0"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.lastSend`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  item.lastSend
                                                                ) ||
                                                                  "Sem informação"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.actions`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.goToRoute(
                                                                      "journeyDetailPage",
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$insert_chart_outlined"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3812886966
                                ),
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "wrapper no-data",
                                  staticStyle: {
                                    height: "550px !important",
                                    "max-height": "550px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "wrapper__card",
                                      staticStyle: {
                                        height: "100% !important",
                                      },
                                    },
                                    [_c("z-loading")],
                                    1
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-5", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "px-5 py-4 d-flex justify-space-between align-center",
                            },
                            [
                              _c("strong", [_vm._v("TABELA DE TEMPLATES")]),
                              _c("z-btn", {
                                attrs: {
                                  small: "",
                                  rounded: "",
                                  primary: "",
                                  text: "",
                                  icon: `mdi-cloud-download`,
                                  "is-loading": _vm.loadingDownloadTemplates,
                                },
                                on: { click: _vm.downloadDocumentTemplates },
                              }),
                            ],
                            1
                          ),
                          !_vm.loadingTemplates
                            ? _c("z-pagination", {
                                attrs: {
                                  "auto-pagination": false,
                                  "icon-previous": "mdi-chevron-left",
                                  "icon-next": "mdi-chevron-right",
                                  "items-per-page": 10,
                                  "number-of-items": _vm.templates.length,
                                  items: _vm.templates,
                                  "current-page": _vm.templateTableFilter.page,
                                  "number-of-pages":
                                    _vm.templateTableFilter.totalPages,
                                },
                                on: {
                                  "click:next":
                                    _vm.goNextPageTemplatesReportsByCompanyId,
                                  "click:previous":
                                    _vm.backPreviousPageTemplatesReportsByCompanyId,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "data",
                                      fn: function ({ paginatedItems }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "table-wrapper",
                                              staticStyle: {
                                                height: "550px !important",
                                                "max-height":
                                                  "550px !important",
                                              },
                                            },
                                            [
                                              _c("z-table", {
                                                staticClass: "table",
                                                attrs: {
                                                  headers: _vm.headersTemplates,
                                                  items: paginatedItems,
                                                  "icon-next":
                                                    "mdi-chevron-right",
                                                  "icon-previous":
                                                    "mdi-chevron-left",
                                                  "is-loading":
                                                    _vm.loadingSortTableTemplate,
                                                },
                                                on: {
                                                  sort: function ($event) {
                                                    return _vm.getSortedTemplateReportsByCompanyId(
                                                      $event
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "no-data",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "no-data-table d-flex align-end justify-center",
                                                            },
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Sem dados disponíveis."
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: `item.openRate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.type ===
                                                                  "email"
                                                                  ? item.openRate.toFixed(
                                                                      2
                                                                    ) + "%" ||
                                                                      "0%"
                                                                  : "-"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.totalSent`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.totalSent ||
                                                                  "0"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.clickRate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.clickRate.toFixed(
                                                                  2
                                                                ) || "0"
                                                              ) + "%"
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.bounceRate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.type ===
                                                                  "email"
                                                                  ? item.bounceRate.toFixed(
                                                                      2
                                                                    ) + "%" ||
                                                                      "0%"
                                                                  : "-"
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.spamRate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.type ===
                                                                  "email"
                                                                  ? item.spamRate.toFixed(
                                                                      2
                                                                    ) + "%" ||
                                                                      "0"
                                                                  : "-"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.optOutRate`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.type ===
                                                                  "email"
                                                                  ? item.optOutRate.toFixed(
                                                                      2
                                                                    ) + "%" ||
                                                                      "0"
                                                                  : "-"
                                                              ) + " "
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3998109079
                                ),
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "wrapper no-data",
                                  staticStyle: {
                                    height: "550px !important",
                                    "max-height": "550px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "wrapper__card",
                                      staticStyle: {
                                        height: "100% !important",
                                      },
                                    },
                                    [_c("z-loading")],
                                    1
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dontHaveAllDatasToCards,
                  expression: "dontHaveAllDatasToCards",
                },
              ],
              staticClass: "wrapper__card__overlay",
            },
            [_vm._m(0)]
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "wrapper__card" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [_c("z-loading")],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "message" }, [
      _c("span", [
        _vm._v(
          " Não foram encontrados resultados com esses filtros. Tente refazer sua pesquisa. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }